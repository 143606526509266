<template>
  <a-spin :spinning="processing">
    <a-table :columns="columns" :dataSource="data" bordered>
      <template slot="row_index" slot-scope="text, record, index">
        <p>{{ index + 1 }}</p>
      </template>
      <template slot="name" slot-scope="text">
        <a href="javascript:;">{{ text }}</a>
      </template>
      <template slot="action" slot-scope="text, record, index">
        <div class="icons-list">
          <!-- <a-icon
            class="action_icons"
            type="eye"
            theme="twoTone"
            @click="download(record.value)"
          /> -->
          <a-icon
            class="action_icons"
            type="cloud-download"
            @click="download(record.value)"
          />
        </div>
      </template>
    </a-table>
  </a-spin>
</template>
<script>
const columns = [
  {
    title: "N°",
    scopedSlots: { customRender: "row_index" }
  },
  {
    title: "Document",
    dataIndex: "name",
    scopedSlots: { customRender: "name" }
  },
  {
    title: "Action",
    scopedSlots: { customRender: "action" }
  }
];

const data = [
  {
    key: "1",
    name: "Demande d'inscription",
    value: 1
  },
  {
    key: "2",
    name: "Déclaration sur l'honneur",
    value: 2
  }
];
// import { mapActions, mapGetters, mapState } from "vuex";
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      data,
      columns,
      processing: true
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    ...mapState({
      adherant: state => state.adherent.adherent
    })
  },
  methods: {
    fetchData() {
      this.processing = true;
      this.$store.cache
        .dispatch("fetchAdherent")
        .then(() => (this.processing = false))
        .catch(() => (this.processing = false));
    },
    download(documentId) {
      if (
        this.adherant.etat !== this.ETAT_ADHERANT_EN_ATTENT &&
        this.adherant.etat !== this.ETAT_ADHERANT_ACCEPTER
      ) {
        this.$_throwAnError(
          "Seulement les adherens acceptés ou en attente peux imprimer ces documents!"
        );
      }
      this.processing = true;
      this.callPdfGenerator({
        id: this.adherant.id,
        type: documentId,
        organisation: this.adherant.organisation_id
      })
        .then(() => (this.processing = false))
        .catch(() => {
          this.processing = false;
          this.$_throwAnError("Un erreur est survenue ");
        });
    },
    ...mapActions({
      callPdfGenerator: "callPdfGenerator"
    })
  }
};
</script>
<style>
.action_icons {
  margin: 4px;
  font-size: large;
}
.icons-list >>> .anticon {
  margin-right: 6px;
  font-size: 24px;
}
</style>
