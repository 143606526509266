var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        { attrs: { type: "flex", justify: "space-around" } },
        [
          _c(
            "a-col",
            { attrs: { span: 18 } },
            [
              _c(
                "h1",
                { staticStyle: { "margin-left": "4%", "margin-bottom": "4%" } },
                [_vm._v("Mes Documents")]
              ),
              _c("DocumentsList")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }