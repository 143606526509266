var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-spin",
    { attrs: { spinning: _vm.processing } },
    [
      _c("a-table", {
        attrs: { columns: _vm.columns, dataSource: _vm.data, bordered: "" },
        scopedSlots: _vm._u([
          {
            key: "row_index",
            fn: function(text, record, index) {
              return [_c("p", [_vm._v(_vm._s(index + 1))])]
            }
          },
          {
            key: "name",
            fn: function(text) {
              return [
                _c("a", { attrs: { href: "javascript:;" } }, [
                  _vm._v(_vm._s(text))
                ])
              ]
            }
          },
          {
            key: "action",
            fn: function(text, record, index) {
              return [
                _c(
                  "div",
                  { staticClass: "icons-list" },
                  [
                    _c("a-icon", {
                      staticClass: "action_icons",
                      attrs: { type: "cloud-download" },
                      on: {
                        click: function($event) {
                          return _vm.download(record.value)
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }