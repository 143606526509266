<template>
  <div>
    <a-row type="flex" justify="space-around">
      <a-col :span="18">
        <h1 style="margin-left:4%;margin-bottom:4%">Mes Documents</h1>
        <DocumentsList></DocumentsList>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import DocumentsList from "./DocumentsList";
export default {
  name: "DocumentsIndex",
  components: { DocumentsList }
};
</script>
